import React from "react";
import styled from "styled-components";
import {useAdmissionData} from '../../hooks/admission'


const Experience = () => {

	const allData = useAdmissionData()
	let experienceData
	allData.map((list) => {
		if (list.slug === "Home-Page") {
			experienceData = list
		}
	})
	return (
		<ExperienceSectionStyled>
			<ExperienceWrapperStyled>
				<h2>{experienceData?.heading ?? ""}</h2>
				<DetailsWrapperStyled>
					{experienceData?.features?.map((report) => {
						return (
							<IndividualDetailStyled key={report.id ?? ""}>
								<span className="detail_one">{report.UpperText ?? ""}</span>
								<span className="detail_two">{report.LowerText ?? ""}</span>
							</IndividualDetailStyled>
						);
					})}
				</DetailsWrapperStyled>
			</ExperienceWrapperStyled>
		</ExperienceSectionStyled>
	);
};

const ExperienceSectionStyled = styled.section``;
const ExperienceWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2.5em 2.8125em 10.75em;
	& h2 {
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2A264C;
		max-width: 17em;
	}

	.our-report-card {
		margin-top: 0.8em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 4em;
		width: 100%;
	}

	@media (max-width: 990px) {
		padding: 1.5em;
	}

	@media (max-width: 600px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		.our-report-card {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 500px) {
		padding: 1em;
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		.our-report-card {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 359px) {
		& h2 {
			font-size: 1.4rem;
			line-height: 30px;
		}
		.our-report-card {
			font-size: 0.85rem;
		}
	}
`;

const DetailsWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 3.5em;
	max-width: 69em;
	margin-top: 4rem;
	.individual_detail {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0.5em;
	}

	.detail_one {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #f05c5c;
		max-width: 15.875rem;
		width: 15.875rem;
	}
	.detail_two {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #2a264c;
	}

	@media (max-width: 1200px) {
		flex-wrap: wrap;
		gap: 2em;
	}

	@media (max-width: 1080px) {
		gap: 2em;
	}
	@media (max-width: 990px) {
		gap: 3em;
	}
`;

const IndividualDetailStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.5em;
	.detail_one {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #fc997c;
	}
	.detail_two {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}

	@media (max-width: 1080px) {
		.detail_one {
			font-size: 1.7rem;
		}
		.detail_two {
			font-size: 0.9rem;
		}
	}

	@media (max-width: 990px) {
		min-width: 11em;
	}
	@media (max-width: 500px) {
		.detail_one {
			font-size: 1.25rem;
			line-height: 24px;
		}
		.detail_two {
			font-size: 0.875rem;
			line-height: 17px;
		}
		&:nth-child(5) {
			flex: 0 0 100%;
		}
		&:nth-child(6) {
			flex: 0 0 100%;
		}
	}

	@media (max-width: 441px) {
		min-width: 9em;
	}
	@media (max-width: 380px) {
		min-width: 7.8em;
	}
	@media (max-width: 359px) {
		min-width: 6.5em;

		.detail_one {
			font-size: 1.3rem;
		}
		.detail_two {
			font-size: 0.77rem;
		}
	}
	@media (max-width: 320px) {
		min-width: 5.9em;

		.detail_one {
			font-size: 1rem;
		}
		.detail_two {
			font-size: 0.7rem;
		}
	}
`;

export default Experience;
