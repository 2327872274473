import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useEnquiryData } from "../../hooks/enquiry";

const ScheduleSession = () => {

	const allData = useEnquiryData()
	const enquiryData = allData.filter((item) => item.slug==="home-page")
	console.log(allData);
	

	return (
		<ScheduleSessionStyled>
			<div id="help_you_make_right_choice">
				<img
					src={enquiryData[0]?.media.url}
					alt={enquiryData[0]?.heading ?? ""}
				/>
				<p id="help_you_make_right_choice_head1">{enquiryData[0]?.heading ?? ""}</p>
				<p id="help_you_make_right_choice_head2">
					{enquiryData[0]?.sub_heading ?? ""}
				</p>
				<Link to={`/form/?formName=${enquiryData[0]?.Button?.redirect_to}`} >
					<button id="schedule_session">{enquiryData[0]?.Button?.heading}</button>
				</Link>
			</div>
		</ScheduleSessionStyled>
	);
};
const ScheduleSessionStyled = styled.div`
	width: 85%;
	margin: 0 7.5% 7.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	#help_you_make_right_choice {
		background: #fff0eb;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem 15.875rem;
	}
	#schedule_session {
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		border: none;
		outline: none;
		background: #f9ce4b;
		border-radius: 8px;
		padding: 0.90625rem 1.75rem;
	}

	#help_you_make_right_choice_head1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}
	#help_you_make_right_choice_head2 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 2rem;
	}
	@media (max-width: 1200px) {
		#help_you_make_right_choice {
			padding: 2rem 0rem;
			width: 100%;
		}
	}
	@media (max-width: 500px) {
		margin: 0rem 2.5rem 0rem 2.5rem;
		#help_you_make_right_choice {
			padding: 2rem;
		}
		#help_you_make_right_choice_head1 {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 29px;
		}
		#help_you_make_right_choice_head2 {
			font-size: 0.875rem;
			line-height: 17px;
			font-weight: 400;
		}
	}
	@media (max-width: 325px) {
		#help_you_make_right_choice {
			padding: 1rem;
		}
		#help_you_make_right_choice_head1 {
			font-size: 1.3rem;
		}
	}
	@media (max-width: 313px) {
		#help_you_make_right_choice {
			padding: 0.5rem;
		}
	}
`;
export default ScheduleSession;
