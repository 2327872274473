import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useHomePresonalizedData } from "../../hooks/home-personlization-team";


const Personalization = () => {

	const data = useHomePresonalizedData()
	let allData
	data.map((list) => {
		if (list.slug === "Personalization") {
		allData = list	
		}
	})

	return (
		<CustomCounsellingStyled>
			<CustomCounsellingWrapperStyled>
				<span key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: allData?.heading ?? "" }} />

				<CustomCounsellingOptionsWrapperStyled>
					{allData?.carousel?.map((list) => {
						return (
							<div key={list.id ?? ""}>
								<div className="custom_counselling_options option_one">
									<img src={list.media.url} alt="" />
									<p className="p-heading">{list.Title ?? ""}</p>
									<p className="p-sub-heading">{list.Description ?? ""}</p>
								</div>
							</div>
						);
					})}
				</CustomCounsellingOptionsWrapperStyled>

				<Link to={`/form/?formName=${allData?.Button?.redirect_to}`}>
					<ScheduleSessionBtnStyled>{allData?.Button?.heading ?? ""}</ScheduleSessionBtnStyled>
				</Link>
			</CustomCounsellingWrapperStyled>
		</CustomCounsellingStyled>
	);
};

const CustomCounsellingStyled = styled.section`
	padding-left: 7.5em;
	padding-right: 7.5em;
	padding-bottom: 7.5em;
	@media (max-width: 980px) {
		padding-left: 4.5em;
		padding-right: 4.5em;
	}
	@media (max-width: 730px) {
		padding: 1.75em;
	}
	@media (max-width: 500px) {
		padding: 1.15em;
	}
`;
const CustomCounsellingWrapperStyled = styled.div`
	background: #fff0eb;
	border-radius: 32px;
	padding: 3em 7.5em;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	#custom_counselling_heading {
		width: 100%;
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		color: #2a264c;
	}

	@media (max-width: 1270px) {
		#custom_counselling_heading {
			text-align: center;
		}
	}
	@media (max-width: 730px) {
		padding: 3em;

		#custom_counselling_heading {
			font-size: 2.6rem;
		}
	}
	@media (max-width: 980px) {
		padding: 3em 2.5em;
	}
	@media (max-width: 600px) {
		#custom_counselling_heading {
			font-size: 2rem;
		}
	}
	@media (max-width: 500px) {
		padding: 2em 0.5em;
		#custom_counselling_heading {
			color: #113a68;
			font-size: 1.75rem;
			line-height: 34px;
		}
	}

	@media (max-width: 353px) {
		padding: 2em 1em;
		#custom_counselling_heading {
			font-size: 1.4rem;
		}
	}
`;

const CustomCounsellingOptionsWrapperStyled = styled.div`
	margin-top: 2.75em;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4em;
	flex-wrap: wrap;
	/* .custom_counselling_options */
	.custom_counselling_options {
		/* display: flex;
		align-items: center;
		justify-content: center; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		gap: 1em;

		min-width: 20.375em;
		max-width: 25.375em;
	}
	/* .custom_counselling_options > p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		color: #396eb0;
	} */
	.p-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-align: center;
		color: #2a264c;
	}

	.p-sub-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #2a264c;
	}
	@media (max-width: 600px) {
		.p-heading {
			font-size: 1rem;
		}
		.p-sub-heading {
			font-size: 1rem;
		}
	}

	@media (max-width: 500px) {
		gap: 2em;
		.custom_counselling_options {
			flex-direction: column;
		}
		/* .custom_counselling_options > p {
			font-size: 1rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		} */
		.p-heading {
			font-size: 1.2rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
			color: #000;
		}
		.p-sub-heading {
			font-size: 1rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		}
		.option_one {
			order: 2;
		}
		.option_two {
			order: 1;
		}
		.option_three {
			order: 3;
		}
	}

	@media (max-width: 353px) {
		margin-top: 1.75em;
		.custom_counselling_options {
			min-width: 100%;
			max-width: 100%;
		}
	}
`;
// const CustomCounsellingLastHeadingStyled = styled.div`
// 	margin-top: 4.25em;
// 	width: 100%;
// 	max-width: 36.875em;
// 	& p {
// 		font-family: "Inter";
// 		font-style: normal;
// 		font-weight: 500;
// 		font-size: 1.25rem;
// 		line-height: 24px;
// 		text-align: center;
// 		color: #113a68;
// 	}
// 	@media (max-width: 600px) {
// 		& p {
// 			font-size: 1.15rem;
// 		}
// 	}
// 	@media (max-width: 600px) {
// 		& > p {
// 			font-size: 1rem;
// 			line-height: 19px;
// 		}
// 	}
// 	@media (max-width: 315px) {
// 		margin-top: 2.25em;
// 		& > p {
// 			font-size: 0.8rem;
// 		}
// 	}
// `;
const ScheduleSessionBtnStyled = styled.button`
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 24px;
	color: #2a264c;
	padding: 1em 2em;
	background: #f9ce4b;
	border-radius: 12px;
	@media (max-width: 500px) {
		font-size: 1rem;
		line-height: 19px;
		padding: 0.90625em 1.84375em;
	}
	@media (max-width: 315px) {
		font-size: 0.8rem;
	}
`;
export default Personalization;
