import React from "react";
import Layout from "../components/layout.component";
import HomePageIndex from "../components/HomePage/homePageIndex";
import 'bootstrap/dist/css/bootstrap.min.css';
const Index = () => {
	return (
		<Layout>
			<HomePageIndex />
		</Layout>
	);
};
export const Head = () => {
	return (
		<>
			<title>The Real School | Online Learning for Children | Online Activities for Kids!</title>
			<meta name="title" content="The Real School | Online Learning for Children | Online Activities for Kids!" />
			<meta name="og:title" content="The Real School | Online Learning for Children | Online Activities for Kids!" />
			<meta name="og:description" content="It's time to prepare your kids with online classes for kids. Online school for kids is incredible for their future. Real school provides you with practical learning and online activities for kids." />
			<meta name="description" content="It's time to prepare your kids with online classes for kids. Online school for kids is incredible for their future. Real school provides you with practical learning and online activities for kids." />
			<meta name="keywords" content="online classes for kids online school for kids online activities for kids"/>
		</>
	)
}
export default Index;
