import React from "react";
import Hero from "../commonComponent/hero"
import Experience from "./experience";
import Personalization from "./personalization";
import EducationalGoal from "./educationalGoal";
import PedagogyAndMethod from "./pedagogyAndMethod";
import TeamVision from "./teamVision";
import BoardsAndProgram from "./boardsAndProgram";
import ParentFeedback from "./parentFeedback";
import ScheduleSession from "./scheduleSession";
import ProgramSection from "./programSection";
import MasterClass from "./masterClass";
const HomePageIndex = () => {
	return (
		<>
			<Hero slug="HomePage" />
			<Experience />
			<Personalization />
			<EducationalGoal />
			<PedagogyAndMethod />
			<TeamVision />
			<BoardsAndProgram />
			<ParentFeedback />
			<ScheduleSession />
			<ProgramSection />
			<MasterClass />
		</>
	);
};

export default HomePageIndex;
