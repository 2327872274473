import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const MasterClass = () => {
  const [masterClass, setMasterclass] = useState([])

  useEffect(() => {
    const asyncFn = async () => {
      const response = await fetch(`https://api.ufaber.com/api/realschool/v3/masterclasses-list/`)
      let data = await response.json()
      data = data.data.sessions[0].masterclass_list.slice(0, 3)
      setMasterclass(data)
    };
    asyncFn()
  }, [])

  return (
    <MasterClassWrapper>
      <Styled>
        <div id="masterclass_offered">

          <h4 id="heading">Live Masterclass</h4>
          <div className="container">
            
            {masterClass?.map((card) => {
              return (
                <>
                <div className="masterclass_cards">
                  <div className="masterclass_items">
                      <Link to={`https://app.therealschool.in/single-masterclass/?id=${card?.master_class_data.id}&date=${card.date}`} target="_blank">
                      <div className="masterclass_img">
                        <img src={card?.master_class_data?.image} alt={card.master_class_data.title ?? ""} />
                      </div>
                      <div className="masterclass_content">
                        <div className="masterclass_content_left">
                          <h4>LIVE ON</h4>
                          <h4>{card.date ?? ""}</h4>
                        </div>

                        <div className="masterclass_content_right">
                          <h4>TIME SLOTS</h4>
                          <h4>{card.ids_time_data[0].slot ?? ""}</h4>
                        </div>

                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            size="1x"
                          />
                      </div>
                    </Link>
                  </div>
                  </div>
                  </>
              );
            })}

          </div>
        </div>
      </Styled>
      <Link to='https://app.therealschool.in/masterclasses/'>
        <button id="view-all">View All</button>
      </Link>
    </MasterClassWrapper>
  );
};
const MasterClassWrapper = styled.section`
font-family:"Montserrat", sans-serif;
  background: #fff0eb;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 70px;
  #view-all {
    font-weight: 700;
    font-size: 1rem;
    line-height: 19px;
    color: #2a264c;
    border: none;
    outline: none;
    background: #f9ce4b;
    border-radius: 8px;
    padding: 0.90625rem 1.75rem;
  }
`;
const Styled = styled.div` 

  #masterclass_offered{
    width:100%;

  }
  .container{
    display: flex;
    flex-direction: row;
    gap: 30px;

  }
  .masterclass_cards {
   display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 0px;
  }

  .masterclass_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 22px;
    overflow: hidden;
    text-decoration: none;
}
  .masterclass_items a {
    color: black;
    text-decoration: none;
  }
  .masterclass_img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    width: 100%;
    height: 250px;
    width: 350px;
  }
  #heading {

		margin-top: 1rem;
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;

  }
  .masterclass_img img {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}

  .masterclass_content {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }

  .masterclass_content h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }

  .masterclass_morebtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .masterclass_morebtn:active {
    transform: translateY(2px);
  }
  #masterclass_btn {
    display: block;
    outline: none;
    border: none;
    width: 13%;
    margin: 0.25em 25% 5em;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
    color: #2a264c;
    padding: 1em 2em;
    background: #f9ce4b;
    border-radius: 12px;

  }
.owl-nav button.owl-prev {
      position: absolute;
      height: 32px;
      width: 32px;
      background: #ffffff;
      border-radius: 50%;
      top: 40%;
      left: -10px;
      border: none;
      outline: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
     .owl-nav button.owl-next {
      position: absolute;
      height: 32px;
      width: 32px;
      background: #ffffff;
      border-radius: 50%;
      top: 40%;
      right: -10px;
      border: none;
      outline: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .owl-nav{
margin-top: 10px;
text-align: center;

    }
  .masterclass_content_right h4 a {
    text-decoration: none;
    color: #000000;
  }
  @media (max-width: 1140px) {
    #masterclass_section {
      padding: 50px;
    }
    .container {
      flex-direction: column;
    }  
    #masterclass_btn {
      width: 40%;
      margin: 0.25em 25% 5em;
    }
  }
  @media (max-width: 800px) {
    #masterclass_section {
      padding: 0px;
    }
    .container {
      flex-direction: column;
    }
  }
  @media (max-width: 600px) {
    h4 {
      margin-top: 30px;
      font-size: 21px;
      line-height: 28px;
    }
    .container {
      flex-direction: column;
      gap:0px;
    }
    #heading {
      font-size: 1.75rem;
    }
    
      
    }
  }
`;

export default MasterClass;
