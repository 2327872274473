import { useStaticQuery, graphql } from "gatsby";

export const useAdmissionData = () => {
    const { allStrapiAdmission } = useStaticQuery(
        graphql`
      query admissionQuery {
        allStrapiAdmission {
          nodes {
            heading
            button{
              heading
              redirect_to
            }
            slug
            features{
                UpperText
                LowerText
            }
          }
        }
      }
    `
    );
    return allStrapiAdmission.nodes;
};
