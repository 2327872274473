import React, { useState } from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import { Link } from "gatsby";
import VideoPopup from "../commonComponent/videoPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useParentData } from "../../hooks/parent-review";
import { useParentHeader } from "../../hooks/parent-review-header";

const ParentFeedback = () => {

	const allData = useParentData()
	const parentData = allData.filter((item) => item.show_on_allpage === true)
	const header = useParentHeader()
	const parentHeaderData = header.filter((item) => item.page_type === "home")
	const [showVideo, setShowVideo] = useState(false);
	const [videoDetails, setVideoDetails] = useState({
		url: "",
		name: "",
	});

	const flickityOptions = {
		cellAlign: "left",
		contain: true,
		pageDots: false,
		freeScroll: true,
		wrapAround: true,
		adaptiveHeight: true,
		draggable: true,
	};

	const onClickPlayVideo = (url) => {
		setVideoDetails({
			url: url,
		});
		setShowVideo(true);
	};

	return (
		<CarouselSectionStyled>
			<CarouselSectionWrapperStyled>
				<div>
					<p id="carousel_heading_1" dangerouslySetInnerHTML={{ __html: parentHeaderData[0]?.heading ?? "" }} />
					<Link to='/success-stories'>
						<VewAllButtonStyle>View All</VewAllButtonStyle>
					</Link>
				</div>

				<CarouselWrapperStyled>
					<Flickity
						className={"main-carousel"}
						elementType={"div"}
						options={flickityOptions}
						disableImagesLoaded={false}
						reloadOnUpdate
						static
					>
						{parentData?.map((studies) => {
							return (
								<div className="carousel-cell" key={studies?.id}>
									<div
										className="carousel_card"
										aria-hidden="true"
										onClick={() =>
											studies?.video[0]?.strapi_component === "video-link.video-link"
												? onClickPlayVideo(
													studies?.video[0]?.video_link,
												)
												: onClickPlayVideo(
													studies?.video[0]?.upload_video?.url,
												)
										}
									>
												<div className = "carousel_image">
											<img src={studies?.thumbnail?.url} alt="FeedBack" />
											<FontAwesomeIcon icon={faPlay} size="1x" className="fa-play" />
									</div>	
										

										<div className="carousel_details">
											<span className="carousel_detail_name">{studies?.heading ?? ""}</span>
											<span className="carousel_detail_desgn">{studies?.sub_heading ?? ""}</span>
										</div>
									</div>
								</div>
							);
						})}
					</Flickity>
				</CarouselWrapperStyled>
				<Link to='/success-stories'>
					<MobileViewAllButtonStyled>View All</MobileViewAllButtonStyled>
				</Link>
			</CarouselSectionWrapperStyled>
			{showVideo && videoDetails.url !== "" && (
				<VideoPopup setShowVideo={setShowVideo} link={videoDetails.url} name={videoDetails.name} />
			)}
		</CarouselSectionStyled>
	);
};
const CarouselSectionStyled = styled.section``;
const CarouselSectionWrapperStyled = styled.div`
	padding-bottom: 5em;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0px;
	height: 100%;
	align-items: center;
	padding-left: 7.5em;
	column-gap: 6.5em;
	a {
		text-decoration:none;
		margin: 1.5em 28% 5em;
	}
	#schedule_session {
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		border: none;
		outline: none;
		background: #f9ce4b;
		border-radius: 8px;
		padding: 0.90625rem 1.75rem;
	}
	#carousel_heading_1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}
	#carousel_heading_2 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		color: #396eb0;
		text-align: left;
		margin-bottom: 2.5em;
	}
	@media (max-width: 1300px) {
		column-gap: 2em;
		padding-left: 3.5em;
	}
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		row-gap: 2em;

		#carousel_heading_2 {
			display: none;
		}
		#carousel_heading_1 {
			text-align: center;
			max-width: 100%;
		}
	}

	@media (max-width: 750px) {
		padding: 0;
	}
	@media (max-width: 599px) {
		row-gap: 1.2em;
		#carousel_heading_1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}

	@media (max-width: 359px) {
		#carousel_heading_1 {
			font-size: 1.3rem;
		}
	}
`;

const VewAllButtonStyle = styled.button`
	border: 1px solid #f9ce4b;
	border-radius: 8px;
	padding: 16px 32px;
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	color: #2a264c;
	background-color: #f9ce4b;
	width: fit-content;
	cursor: pointer;
	@media (max-width: 800px) {
		display: none;
	}
`;

const CarouselWrapperStyled = styled.div`
	.carousel-cell {
		height: 25em;
		margin: 1em;
	}
	.carousel_card {
		background: #ffffff;
		box-shadow: 0px 4px 25px rgba(17, 58, 104, 0.15);
		border-radius: 16px;
		padding: 0.5em;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.75em;
		min-width: 16.4375em;
		max-width: 16.4375em;
		height: 23.3125em;
		margin-right: 1.5em;
		position: relative !important;
	}
	.carousel_image {
		border-radius: 12px;
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.carousel_image img {
		min-width: 100%;
		max-width: 100%;
		height: 18.125em;
		object-fit: cover;
	}
	.fa-play {
		font-size: 3rem;
		color: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}
	.carousel_details {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		gap: 0.25em;
		padding-left: 0.5em;
	}
	.carousel_detail_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 24px;
		color: #2a264c;
	}
	.carousel_detail_desgn {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #8295ab;
	}

	.flickity-prev-next-button.previous {
		display: none;
	}
	.flickity-prev-next-button.next {
		width: 64px;
		height: 64px;
		right: 40px;
		top: 47%;
		background: #F05C5C;
		box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
		color: #fff;
	}
	.flickity-button:active {
		opacity: 1;
	}

	@media (max-width: 1300px) {
		.flickity-prev-next-button.next {
			right: 15px;
		}
	}
	@media (max-width: 1100px) {
		.carousel_card {
			margin-right: 1em;
		}
	}
	@media (max-width: 750px) {
		padding: 1em;

		.carousel_card {
			margin-right: -1em;
		}
	}

	@media (max-width: 500px) {
		.carousel_card {
			min-width: 12.5em;
			max-width: 12.5em;
			height: 17.5em;
		}
		.carousel_detail_name {
			font-size: 1rem;
			line-height: 19px;
		}
		.carousel_detail_desgn {
			font-size: 0.75rem;
			line-height: 15px;
		}
		.carousel_image img {
			height: 13.5em;
		}
		.flickity-prev-next-button.next {
			height: 40px;
			width: 40px;
			top: 50%;
		}
		.carousel-cell {
			height: 17em;
		}
	}
`;
const MobileViewAllButtonStyled = styled.button`
	display: none;
	@media (max-width: 800px) {
		display: block;
		border: 1px solid  #2a264c;;
		border-radius: 8px;
		padding: 16px 32px;
		outline: none;
		border: none;

		margin: 1.5em 28% 5em;
		cursor: pointer;
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color:  #2a264c;;
		background-color: #f9ce4b;
		width: fit-content;
		cursor: pointer;
		margin: auto;
	}
`;

export default ParentFeedback;
