import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useHomePresonalizedData } from "../../hooks/home-personlization-team";

const TeamVision = () => {

	const data = useHomePresonalizedData()
	let allData
	data.map((list) => {
		if (list.slug === "Team") {
			allData = list
		}
	})

	return (
		<TeamVisionStyled>
			<TeamVisionWrapperStyled>
				<h5>{allData?.heading ?? ""}</h5>
				<TeamVisionCardWrapperStyled>
					{allData?.carousel?.map((blog, index) => (
						<TeamVisionCardsStyled key={index}>
							<div className="studying_in_US_canada_card_image_wrapper">
								<img src={blog.media.url} alt={ "img"} />
							</div>
							<p className="studying_in_US_canada_card_heading">{blog.Title ?? ""}</p>
							<p className="studying_in_US_canada_card_info">{blog.Description ?? ""}</p>
						</TeamVisionCardsStyled>
					))}
				</TeamVisionCardWrapperStyled>
			</TeamVisionWrapperStyled>
		</TeamVisionStyled>
	);
};

const TeamVisionStyled = styled.section`
	padding: 0 2rem;

	@media (max-width: 500px) {
		padding: 0 0.75rem;
`;
const TeamVisionWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 7rem;
	width: 100%;
	& h5 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
		/* max-width: 35.4375rem; */
		margin-bottom: 3.3rem;
	}
	& small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 4.5rem;
	}

	@media (max-width: 500px) {
		& small {
			margin-bottom: 3rem;
			font-size: 1rem;
			line-height: 19px;
		}

		& h5 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
	@media (max-width: 333px) {
		font-size: 1.5rem;
	}
`;

const TeamVisionCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 2.25rem;
	width: 100%;
	@media (max-width: 580px) {
		& h5 {
			font-size: 2.5rem;
		}
	}
`;

const TeamVisionCardsStyled = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	min-height: 21.75rem;
	max-height: 21.75rem;
	min-widht: 24rem;
	max-width:24rem;
	justify-content: space-between;
	.studying_in_US_canada_card_read_more {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #fc997c;
		text-align: center;
		cursor: pointer;
	}
	.studying_in_US_canada_card_image_wrapper {
		border-radius: 8px;
		overflow: hidden;
		min-height: 13.5rem;
		max-height: 13.5rem;
		min-width: 22.5rem;
		max-width: 22.5rem;
		img {
			min-height: 13.5rem;
			max-height: 13.5rem;
			min-width: 22.5rem;
			max-width: 22.5rem;
			object-fit: cover;
		}
	}
	.studying_in_US_canada_card_info {
		font-family: "Inter";
		font-style: normal;

		font-weight: 400;
		font-size: 0.75rem;
		line-height: 17px;
		color: #2a264c;
		max-width: fit-content;
		overflow: hidden;
		text-overflow: clip;
	}
	.studying_in_US_canada_card_heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1.3rem;
		line-height: 24px;
		color: #2a264c;
		max-width: fit-content;
		margin: 10px 0px;
	}
	@media (max-width: 500px) {
		min-width: 21rem;
		max-width: 21rem;
		min-height: fit-content;
		max-height:fit-content;

		.studying_in_US_canada_card_heading {
			padding-left: 0rem;
		}
		.studying_in_US_canada_card_info {
			padding-left: 0rem;
			font-size: 0.875rem;
			line-height: 17px;
			margin-bottom: 0.875rem;
		}
		.studying_in_US_canada_card_read_more {
			font-size: 0.875rem;
			line-height: 17px;
		}
		.studying_in_US_canada_card_image_wrapper {
			height: 11.25rem;
			min-width: 20rem;
			max-width: 20rem;
		}
	}
	@media (max-width: 400px) {
		.studying_in_US_canada_cards {
			min-width: 100%;
			max-width: 100%;
			min-height: 100%;
			max-height: 100%;
		}
		.studying_in_US_canada_card_image_wrapper {
			height: 11.25rem;
			min-width: 100%;
			max-width: 100%;
			margin-bottom: 0.75rem;
		}
		.studying_in_US_canada_card_info {
			max-width: 100%;
		}
		.studying_in_US_canada_card_heading {
			max-width: 100%;
			margin-bottom: 0.5rem;
		}
	}
	@media (max-width: 333px) {
		.studying_in_US_canada_card_heading {
			font-size: 0.85rem;
		}
		.studying_in_US_canada_card_info {
			font-size: 0.75rem;
		}
		.studying_in_US_canada_card_read_more {
			font-size: 0.75rem;
		}
	}
`;
export default TeamVision;
