import { useStaticQuery, graphql } from "gatsby";

export const useHomeBoardData = () => {
    const { allStrapiHomeBoardProgram } = useStaticQuery(
        graphql`
        query homeboardquery{
            allStrapiHomeBoardProgram{
                nodes{
                    heading
                    crads{
                        Image{
                            url
                        }
                        Text
                        sub_heading
                        redirect_to
                    }
                }
            }
        }
        `
    )
    return allStrapiHomeBoardProgram.nodes
}